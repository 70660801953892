import { Box, Heading, Text, VStack, useMediaQuery } from '@chakra-ui/react';

type MiddleBannerModuleProps = {
  textTitle: string;
  textDescription: any;
  isAuthenticated: boolean;
};

export default function MiddleBannerModule({
  textTitle,
  textDescription,
  isAuthenticated,
}: MiddleBannerModuleProps) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <Box px={['5', '12', '20', '24']} id="how-to-play">
      <VStack
        justifyContent="center"
        align="start"
        py="2%"
        pl="1%"
        // w={isMobile ? ['290px'] : ['full']}
        
      >
        <Heading
          pt={10}
          pb={[5, 5, 5]}
          variant="lpBannerTitle"
          alignSelf={isAuthenticated ? 'center' : 'start'}
          w={isMobile ? ['290px'] : ['full']}
        >
          {textTitle}
        </Heading>
        <Text variant="lpDescriptionInfos">{textDescription}</Text>
      </VStack>
    </Box>
  );
}
