import Header from '@components/Header';
import Sidebar from '@components/Sidebar';
import Footer from '@components/Footer';
import { Box, useDisclosure } from '@chakra-ui/react';

export default function Layout({
  children,
  background,
}: {
  children: any;
  background?: string;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" background={background ?? 'lpBodyColorGradient'}>
      {/* <Sidebar
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      /> */}
      <Sidebar onClose={onClose} isOpen={isOpen} />
      <Header onOpen={onOpen} />
      <Box>{children}</Box>
      <Footer />
    </Box>
  );
}
