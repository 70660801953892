import { Prize } from '@interfaces/Prize';
import { prizeToast } from '@lib/toast';
import logger from '@logger/logger';
import { getPrizesReq } from '@services/prizes';
import { getNotificationsReq } from '@services/notifications';
// import { useSession } from 'next-auth/react';
import React, { createContext, useEffect, useState } from 'react';
import { useSession } from '@hooks/useSession';

interface PrizesContextInterface {
  prizes: Prize[] | undefined;
  setPrizes({}: Prize[]): any;
  getPrizes(): any;
}

interface PrizesInterfaceProps {
  children: React.Component | any;
  prizes?: Prize[];
}

export const PrizesContext = createContext<PrizesContextInterface>(
  {} as PrizesContextInterface
);

export default function PrizesProvider({
  children,
  prizes: prizeList,
}: PrizesInterfaceProps) {
  const [prizes, setPrizes] = useState<Prize[] | undefined>(prizeList);
  const [_, setProcessing] = useState(false);
  const { status } = useSession();
  const localStorageKey = `07511df3-dbbf_get_prizes`;

  const handlerMinutesGetPrize = (isInterval: boolean) => {
    if (typeof window === 'undefined') return;
    logger.debug('handlerMinutesGetPrize', isInterval);
    let logicPrizesStr = window.localStorage.getItem(localStorageKey);
    let sendRequest = false;
    let now = new Date().valueOf();
    if (logicPrizesStr != null) {
      let logicPrizes = JSON.parse(logicPrizesStr);
      if (logicPrizes.dt_timestamp_expires < now) {
        sendRequest = true;
      }
    } else if (!isInterval) {
      sendRequest = true;
    }
    if (sendRequest || isInterval) {
      getPrizesNotViewed();
      window.localStorage.setItem(
        localStorageKey,
        JSON.stringify({
          dt_timestamp_expires: new Date(now + 30 * 60000).valueOf(),
        })
      );
    }
  };

  useEffect(() => {
    if (status === 'authenticated') {
      handlerMinutesGetPrize(false);
      // setInterval(() => {
      //   handlerMinutesGetPrize(true);
      // }, 30 * 60000);
    }
  }, [status]);

  const getPrizesNotViewed = async () => {
    let getPrizesResp = await getNotificationsReq({ has_viewed: false });
    if (getPrizesResp) {
      // setPrizes(getPrizesResp);
      prizeToast({
        notifications: getPrizesResp
      });
    }
  };

  const getPrizes = async () => {
    setProcessing(true);
    //fetch prizes
    let getPrizesResp = await getPrizesReq();

    if (getPrizesResp) {
      setPrizes(getPrizesResp);
    }
    setProcessing(false);
  };

  return (
    <PrizesContext.Provider
      value={{
        prizes,
        getPrizes,
        setPrizes,
      }}
    >
      {children}
    </PrizesContext.Provider>
  );
}
