import {
  Box,
  Card,
  Heading,
  Image,
  Stack,
  Text,
  VStack,
  useMediaQuery,
} from '@chakra-ui/react';
import ButtonStart from '../../ButtonStart';

type BootomBannerModuleProps = {
  textTitle: string;
  titleDescription: string;
  titleDescription2: string;
  textDescription: any;
  textDescription2: any;
  urlImage: string;
  urlImage2: string;
  textButton: string;
  textLoadingButton: string;
  onClick: () => void;
  loading: boolean;
};
export default function BootomBannerModule({
  textTitle,
  titleDescription,
  titleDescription2,
  textDescription,
  textDescription2,
  urlImage,
  urlImage2,
  textButton,
  textLoadingButton,
  onClick,
  loading,
}: BootomBannerModuleProps) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <Box px={['5', '12', '20', '24']} id="faq">
      <VStack
        justifyContent="center"
        align="start"
        pt={['10%', '2%', '2%', '2%']}
        pb="2%"
        pl="1%"
        spacing={'8'}
      >
        <Heading variant="lpBannerTitle">{textTitle}</Heading>
        {/* <Card maxW="sm" w={['290px', '384px']} h={['304px', '480px']}>
          <Image
            objectFit="cover"
            w={['290px', '384px']}
            h={['142px', '188px']}
            src={urlImage}
            alt="Chakra UI"
          />

          <Stack pt={'2'} pl={'5'} spacing="2">
            <Heading variant="lpBootomTitle">{titleDescription}</Heading>
            <Text variant="lpBootomText">{textDescription}</Text>
          </Stack>
        </Card> */}

        <Stack
          spacing={{
            base: '30px',
            sm: '30px', // 480px
            md: '30px', // 768px
            lg: '50px', // 992px
            xl: '100px', // 1280px
            '2xl': '100px', // 1536px
          }}
          // justify="space-between"
          // align="center"
          direction={{
            base: 'column',
            sm: 'column', // 480px
            md: 'column', // 768px
            lg: 'row', // 992px
            xl: 'row', // 1280px
            '2xl': 'row', // 1536px
          }}
          w={'full'}
        >
          <Card
            // maxW="sm"
            // w={['290px', '384px']}
            borderTopRadius={'10'}
            h={['', '480px']}
            maxW={'410px'}
          >
            <Image
              objectFit="cover"
              // w={['290px', '384px']}
              borderTopRadius={'10'}
              h={['142px', '200px']}
              src={urlImage}
              alt="Chakra UI"
            />

            <Stack pt={'4'} ml={5} spacing="5">
              <Heading size="md" variant="lpBootomTitle">
                {titleDescription}
              </Heading>
              <Box
                w={{
                  base: 'full',
                  sm: 'full', // 480px
                  md: 'full', // 768px
                  lg: 'full', // 992px
                  xl: '357px', // 1280px
                  '2xl': '357px', // 1536px
                }}
              >
                <Text variant="lpBootomText">{textDescription}</Text>
              </Box>
            </Stack>
          </Card>
          <Card
            // maxW="sm"
            // w={['290px', '384px']}
            borderTopRadius={'10'}
            h={['', '480px']}
            maxW={'410px'}
          >
            <Image
              objectFit="cover"
              // w={['290px', '384px']}
              borderTopRadius={'10'}
              h={['142px', '200px']}
              src={urlImage2}
              alt="Chakra UI"
            />

            <Stack pt={'4'} px={'5'} spacing="5">
              <Heading size="md" variant="lpBootomTitle">
                {titleDescription2}
              </Heading>
              <Box 
                w={{
                  base: 'full',
                  sm: 'full', // 480px
                  md: 'full', // 768px
                  lg: 'full', // 992px
                  xl: '357px', // 1280px
                  '2xl': '357px', // 1536px
                }}>

              <Text variant="lpBootomText">{textDescription2}</Text>
              </Box>
            </Stack>
          </Card>

          {!isMobile && (
            <VStack
              // position="absolute"
              // right={{
              //   base: '6%',
              //   sm: '6%', // 480px
              //   md: '6%', // 768px
              //   lg: '6%', // 992px
              //   xl: '11%', // 1280px
              //   '2xl': '11%', // 1536px
              // }}
              align={'center'}
              h={['304px', '480px']}
              w={'210px'}
              maxW={'410px'}
            >
              <ButtonStart
                onClick={onClick}
                loading={loading}
                textLoadingButton={textLoadingButton}
                textButton={textButton}
              />
            </VStack>
          )}
        </Stack>
      </VStack>
    </Box>
  );
}
