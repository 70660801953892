import {
  Box,
  HStack,
  Heading,
  Image,
  Text,
  VStack,
  useMediaQuery,
} from '@chakra-ui/react';
import StickerSubscribe from '@components/StickerSubscribe';

type MiddleBannerModuleProps = {
  urlImage: string;
  urlImage2: string;
  urlImage3: string;
  urlImage4: string;
  textTitle: string;
  textDescription: any;
  textDescription2: any;
  textDescription3: any;
  textDescription4: any;
  isAuthenticated: boolean;
};
export default function MiddleBannerModule({
  urlImage,
  urlImage2,
  urlImage3,
  // urlImage4,
  textTitle,
  textDescription,
  textDescription2,
  textDescription3,
}: // textDescription4,
// isAuthenticated
MiddleBannerModuleProps) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <Box id="how-to-play" p={[0, 5]}>
      <VStack
        spacing={{
          base: '70px',
          sm: '70px', // 480px
          md: '70px', // 768px
          lg: 5, // 992px
          xl: 5, // 1280px
          '2xl': 5, // 1536px
        }}
        py={[2, 5, 5]}
      >
        {isMobile && <StickerSubscribe />}
        <VStack justifyContent="center" align="start" spacing={10}>
          <Heading variant="lpBannerTitle" alignSelf={'start'}>
            {isMobile ? textTitle : 'Como participar'}
          </Heading>
          <VStack align={'start'} spacing={7}>
            <HStack spacing={'20px'}>
              <Image w={['58', '60px']} h={['58', '60px']} src={urlImage} />
              <Text variant="lpDescriptionInfos">{textDescription}</Text>
            </HStack>
            <HStack spacing={'20px'}>
              <Image w={['58', '60px']} h={['58', '60px']} src={urlImage2} />
              <Text variant="lpDescriptionInfos">{textDescription2}</Text>
            </HStack>
            <HStack spacing={'20px'}>
              <Image w={['58', '60px']} h={['58', '60px']} src={urlImage3} />
              <Text variant="lpDescriptionInfos">{textDescription3}</Text>
            </HStack>
          </VStack>
          {/* <HStack>
          <Image w={['58', '120']} h={['58', '120']} src={urlImage4} />
          <Text pl={[5, 10]} variant="lpDescriptionInfos">
            {textDescription4}
          </Text>
        </HStack> */}
          {!isMobile && <StickerSubscribe />}
        </VStack>
      </VStack>
    </Box>
  );
}
