import { Button } from '@chakra-ui/react';

interface ButtonStartProps {
  onClick(): void;
  loading: boolean;
  textLoadingButton: string;
  textButton: string;
}

export default function ButtonStart({
  onClick,
  loading,
  textLoadingButton,
  textButton,
}: ButtonStartProps) {
  return (
    <Button
      onClick={onClick}
      isLoading={loading}
      width={{
        base: '90px',
        sm: '90px', // 480px
        md: '90px', // 768pxu
        lg: '120px', // 992px
        xl: '120px', // 1280px
        '2xl': '120px', // 1536px
      }}
      height={{
        base: '90px',
        sm: '90px', // 480px
        md: '90px', // 768px
        lg: '120px', // 992px
        xl: '120px', // 1280px
        '2xl': '120px', // 1536px
      }}
      bgColor="#FFC722"
      borderRadius="full"
      loadingText={textLoadingButton}
      spinnerPlacement="start"
      color="#000"
      textAlign="center"
      fontFamily="AMX"
      fontSize={["18px", "20px"]}
      fontStyle="normal"
      fontWeight="500"
      lineHeight="normal"
    >
      {textButton}
    </Button>
  );
}
