import BootomBannerModule from './components/Banners/BootomBanner';
// import BootomBannerLoged from './components/Banners/BootomBannerLoged';
import HeadingBannerModule from './components/Banners/HeadingBanner';
import MiddleBannerModule from './components/Banners/MiddleBanner';
import MiddleBannerLoged from './components/Banners/MiddleBannerLoged';
// import WinnerPrizes from '@components/WinnerPrizes';
// import { useSession } from 'next-auth/react';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import router from 'next/router';
import { loginUrl } from '@lib/consts_envs';
import { Stack, VStack, useMediaQuery } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useStages } from '@hooks/useStages';
import StagesView from './components/Stages';
import { useSession } from '@hooks/useSession';

export default function DefaultModule() {
  const { t } = useTranslation('landing-page');
  const { data: _session, status } = useSession();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { stages, loadNewStages } = useStages();

  useEffect(() => {
    if (status == 'authenticated') {
      loadNewStages();
    }
  }, [status]);
  return (
    <div>
      {/* {status === 'authenticated' ? (
        <HeadingBannerLoged
          urlImage={'/imgs/oston/claro/header.svg'}
          urlImageMobile={'/imgs/oston/claro/header-mobile.svg'}
          textTitle={t('banner-header-title-loged')}
        />
      ) : ( */}
      <HeadingBannerModule
        urlImage={'/imgs/oston/BannerHeader.png'}
        urlImageMobile={'/imgs/oston/BannerHeaderMobile.png'}
        textTitle={t('banner-header-title')}
        textDescription={t('banner-header-description')}
        textButton={t('banner-button')}
        onClick={() => {
          router.push(loginUrl);
        }}
        loading={status === 'loading'}
        isAuthenticated={status === 'authenticated'}
        textLoadingButton={t('banner-button-loading')}
      />
      {/* )} */}
      {status === 'authenticated' ? (
        <VStack align={{
          base: 'start',
          sm: 'start', // 480px
          md: 'start', // 768px
          lg: 'center', // 992px
          xl: 'center', // 1280px
          '2xl': 'center', // 1536px
        }}>
          <Stack
            direction={{
              base: 'column',
              sm: 'column', // 480px
              md: 'column', // 768px
              lg: 'row', // 992px
              xl: 'row', // 1280px
              '2xl': 'row', // 1536px
            }}
            px={{
              base: '4',
              sm: '4', // 480px
              md: '4', // 768px
              lg: '12', // 992px
              xl: '20', // 1280px
              '2xl': '24', // 1536px
            }}
            py={5}
            spacing={{
              base: '5px',
              sm: '5px', // 480px
              md: '5px', // 768px
              lg: '70px', // 992px
              xl: '70px', // 1280px
              '2xl': '70px', // 1536px
            }}
          >
            <StagesView stages={stages} />
            <MiddleBannerLoged
              urlImage={'/imgs/oston/RecargaApartirDe20.png'}
              urlImage2={'/imgs/oston/ConcorrePremioInstantaneos.png'}
              urlImage3={'/imgs/oston/RecargaGratis.png'}
              urlImage4={'/imgs/oston/Claro2x.png'}
              textTitle={t('banner-1-title')}
              isAuthenticated={status === 'authenticated'}
              textDescription={
                <Trans
                  i18nKey="landing-page:banner-1-description"
                  components={[<span style={{ fontWeight: 'bold' }} />]}
                />
              }
              textDescription2={
                <Trans
                  i18nKey="landing-page:banner-1-description-2"
                  components={[<br />, <span style={{ fontWeight: 'bold' }} />]}
                />
              }
              textDescription3={
                <Trans
                  i18nKey="landing-page:banner-1-description-3"
                  components={[<br />, <span style={{ fontWeight: 'bold' }} />]}
                />
              }
              textDescription4={
                <Trans
                  i18nKey="landing-page:banner-1-description-4"
                  components={[<br />, <span style={{ fontWeight: 'bold' }} />]}
                />
              }
            />
          </Stack>
        </VStack>
      ) : (
        <MiddleBannerModule
          textTitle={t('banner-1-title')}
          textDescription={
            <Trans
              i18nKey="landing-page:banner-1-description-Module"
              components={[
                <span style={{ fontWeight: 'bold' }} />,
                isMobile ? <span /> : <br />,
              ]}
            />
          }
          isAuthenticated={false}
        />
      )}
      {status === 'authenticated' ? (
        // <BootomBannerLoged textTitle={t('banner-2-title')} />
        <></>
      ) : (
        <BootomBannerModule
          textTitle={t('banner-2-title-Module')}
          textDescription={
            <Trans
              i18nKey="landing-page:banner-2-description"
              components={[<span style={{ fontWeight: '700' }} />]}
            />
          }
          textDescription2={
            <Trans
              i18nKey="landing-page:banner-2-description-2"
              components={[<span style={{ fontWeight: '700' }} />]}
            />
          }
          urlImage={'/imgs/oston/presenteBootom.png'}
          urlImage2={'/imgs/oston/emojeBootom.png'}
          titleDescription={t('banner-2-description-title')}
          titleDescription2={t('banner-2-description-title-2')}
          onClick={() => {
            router.push(loginUrl);
          }}
          loading={status === 'loading'}
          textButton={t('banner-button')}
          textLoadingButton={t('banner-button-loading')}
        />
      )}
      {/* <WinnerPrizes /> */}
    </div>
  );
}
