import { Box, VStack, useMediaQuery } from '@chakra-ui/react';
import CustomNextImage from '@components/ChakraNextImage';
import ButtonStart from '../../ButtonStart';
// import CustomNextImage from '@components/ChakraNextImage';
// import Draw from '../../Claro/Draw';
// import Plus from '../../Claro/Plus';
// import Packages from '../../Claro/Packages';

type HeadingBannerModuleProps = {
  urlImage: string;
  urlImageMobile: string;
  textTitle: string;
  textDescription: string;
  textButton: string;
  textLoadingButton: string;
  onClick: () => void;
  loading: boolean;
  isAuthenticated: boolean;
};
export default function HeadingBannerModule({
  urlImage,
  urlImageMobile,
  textButton,
  textLoadingButton,
  onClick,
  loading,
  isAuthenticated,
}: HeadingBannerModuleProps) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  if (isMobile) {
    return (
      <Box>
        <CustomNextImage
          src={urlImageMobile}
          alt={'Prezao premiado'}
          width="768px" //width={'634px'}
          height="542.72px" //height={'382px'}
        />
        {isAuthenticated == false && (
          <Box position="absolute" right="20px" marginTop={'-50px'}>
            <ButtonStart
              onClick={onClick}
              loading={loading}
              textLoadingButton={textLoadingButton}
              textButton={textButton}
            />
            {/* <Button
            onClick={onClick}
            isLoading={loading}
            loadingText={textLoadingButton}
            width="90px"
            height="90px"
            bgColor="#FFC722"
            borderRadius="full"
            spinnerPlacement="start"
            color="#000"
            textAlign="center"
            fontFamily="AMX"
            fontSize="18px"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="normal"
            >
            {textButton}
          </Button> */}
          </Box>
        )}
      </Box>
    );
  }

  return (
    <VStack bgGradient="linear(to-r,  #F1402C, #D81E1A)">
      <CustomNextImage
        src={urlImage}
        alt={'Prezao premiado'}
        width="1512px" //width={'634px'}
        height="388px" //height={'382px'}
      />
    </VStack>
  );
}
