import {
  Box,
  // Container,
  // VStack,
  // Text,
  // Link,
  // Stack,
  // useMediaQuery,
  Image,
  HStack,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
// import Trans from 'next-translate/Trans';

export default function Footer({ minH }: { minH?: string }) {
  // const [isMobile] = useMediaQuery('(max-width: 768px)');
  // const router = useRouter();
  // const { t } = useTranslation('landing-page');
  return (
    <Box 
      pt={[5, 6, 9, 12]}
      bg={'lpFooterColor'}
      as={motion.div}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition="1s linear"
      viewport={{ once: true }}
      position={"relative"}
      zIndex={"overlay"}
      bottom={0}
      minH={minH}
      w={"full"}
    >
      {/* <Container bg={'#E2E2E2'}
        zIndex={0}
        px={0}
        maxW={{ base: 'full', md: 'full', xl: 'full' }}
        pb={4}
      >
        <VStack px={['5', '12', '20', '24']} py={5} align={'start'} zIndex={0} id='regulations' >
          <Stack direction={['column', 'column', 'row']} spacing={2}  pl="1%">
            <Text
              pt={'30px'}
              pb={4}
              fontSize={['20px', '28px', '28px']}
              fontFamily={'AMX'}
              lineHeight={"28px"}
              fontWeight={700}
              color="#DA291C"
            >
              Regulamento
            </Text>
          </Stack>
          <VStack
            color="#000000"
            fontFamily={'AMX'}
            fontSize={['14px', '18px']}
            fontWeight={400}
            lineHeight={"28px"}
            textAlign={['start', 'start']}
            align={"start"}
            spacing={[3,6]}
            // ml={"5vw"}
            mr={"10vw"}
            pl="1%"
          >
            <Trans
              i18nKey="landing-page:banner-footer-description"
              components={[<p />]}
            />
          </VStack>
          {/* <Link
            alignSelf={'center'}
            href="https://www.maquinadepromos.com.br"
            color="#000000"
            fontSize={['12px', 'sm']}
            fontFamily={'Montserrat'}
            fontWeight={700}
            textAlign="center"
            target="_blank"
          >
            Desenvolvido por Máquina de Promos
          </Link> * /}
        </VStack>
      </Container> */}
      <Box
        px={['10px', '10%']}
        bg="red"
        w="100%"
        h="100%"
        py={7}
        color="white"
      >
        <HStack justifyContent={'end'} w={"full"}>
            <Image w={'77px'} h={'78px'} src={'imgs/oston/ClaroFooter.png'} />
        </HStack>
      </Box>
    </Box>
  );
}
