import { Box, HStack, Image, Text } from '@chakra-ui/react';

export default function StickerSubscribe() {
  return (
    <HStack
      spacing={0}
      bgColor={'#FFC722'}
      borderRadius={10}
      maxW={"460px"}
      w={{
        base: '300px',
        sm: '300px', // 480px
        md: 'auto', // 768px
        lg: "auto", // 992px
        xl: "auto", // 1280px
        '2xl': "auto", // 1536px
      }}
      h={{
        base: '80px',
        sm: '129px', // 480px
        md: '129px', // 768px
        lg: '129px', // 992px
        xl: '129px', // 1280px
        '2xl': '129px', // 1536px
      }}
      mx={2}
    >
      <Image
        src="/imgs/oston/sticker.png"
        width={["100px","auto"]}
        h={{
          base: '80px',
          sm: '129px', // 480px
          md: '129px', // 768px
          lg: '129px', // 992px
          xl: '129px', // 1280px
          '2xl': '129px', // 1536px
        }}
      />
      <Box  p={{
          base: 4,
          sm: 4, // 480px
          md: 2, // 768px
          lg: 6, // 992px
          xl: 6, // 1280px
          '2xl': 6, // 1536px
        }}>
        <Text
          // color="#000"
          // fontFamily="AMX"
          fontSize={{
            base: '14px',
            sm: '14px', // 480px
            md: '14px', // 768px
            lg: '14px', // 992px
            xl: '18px', // 1280px
            '2xl': '18px', // 1536px
          }}
          // fontStyle="normal"
          // fontWeight="400"
          // lineHeight="28px"
          variant="lpDescriptionInfos"
        >
          Assinando o serviço, por apenas R$3,99/semana,{' '}
          <b>você dobra as suas chances de ganhar.</b>
        </Text>
      </Box>
    </HStack>
  );
}
