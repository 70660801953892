import {
  Box,
  Card,
  CardBody,
  Center,
  Heading,
  Image,
  Skeleton,
  Stack,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
// import CustomNextImage from '@components/ChakraNextImage';
import { GameDetailType } from '@interfaces/Game';
import { Stage } from '@interfaces/Stage';
import { useRouter } from 'next/router';

export default StagesView;

interface StagesViewProps {
  stages?: Stage[];
}

function StagesView({ stages }: StagesViewProps) {
  const router = useRouter();

  if (stages == undefined) {
    return (
      <Box p={[0, 10]}>
        <Wrap
          spacing="60px"
          justify="center"
          alignItems="center"
          margin="auto"
          paddingTop={['10', '14']}
        >
          <WrapItem>
            <Skeleton minH={'250px'} width={'250px'} />
          </WrapItem>
          <WrapItem>
            <Skeleton minH={'250px'} width={'250px'} />
          </WrapItem>
        </Wrap>
      </Box>
    );
  }

  return (
    <Box p={[0, 5]}  >
       <VStack spacing={5} py={[2, 5, 5]} align={"start"}>
      <Heading variant="lpBannerTitle" alignSelf={'start'}>
        Escolha o game e concorra a prêmios na hora.
      </Heading>
      {/* <Box w={{
            base: '300px',
            sm: '500px', // 480px
            md: '500px', // 768px
            lg: '700px', // 992px
            xl: '700px', // 1280px
            '2xl': '700px', // 1536px
          }} overflowX={"scroll"}> */}
        <Stack dir={"row"} align="start" mt={3} spacing={"40px"} w="full" >
          {stages.length > 0 &&
            stages.map((stage) => {
              return (
                stage.games.length > 0 &&
                stage.games.map((game) => (
                  <Box p={2} key={game.idGame} w="full">
                    <Card
                      // maxW="sm"
                      w={['full', '410px']}
                      key={game.idGame}
                      borderTopRadius={'10'}
                      // mr={'10vw'}
                      // h={['304px', '80px']}
                      maxW={'410px'}
                    >
                      <Image
                        objectFit="cover"
                        // w={['290px', '410px']}
                        borderTopRadius={'10'}
                        h={['180px', '258px']}
                        src={
                          game.type == GameDetailType.Roulette
                            ? '/imgs/oston/Roulette.png'
                            : game.type == GameDetailType.ScratchCard
                            ? '/imgs/oston/ScratchCard.png'
                            : game.type == GameDetailType.RouletteSmart
                            ? '/imgs/oston/Roulette.png'
                            : game.type == GameDetailType.Quiz
                            ? '/imgs/oston/Quiz.png'
                            : '/imgs/oston/Keyword.png'
                        }
                        alt="Game "
                      />

                      <CardBody>
                        <Center h={['100px']}>
                          <Center
                            borderRadius={40}
                            bgColor={'#FFC722'}
                            w={['177px']}
                            h={['58px']}
                            minH="58px"
                            cursor={"pointer"}
                            onClick={() => {
                              router.push(
                                `/game?${new URLSearchParams({
                                  ig: String(game.idGame),
                                  is: String(stage.id),
                                  tp: String(game.type),
                                  inf: Buffer.from(
                                    JSON.stringify({
                                      rbcode: String(game.rebilling_code),
                                      ipo: game.paid_only ? 'true' : 'false',
                                      us: game.utm_source,
                                      ug: game.utm_game,
                                    }),
                                    'utf8'
                                  ).toString('base64'),
                                })}`
                              );
                            }}
                            >
                            <Text 
                            fontFamily="AMX"
                            fontWeight={500}
                            fontSize={["16px","20px"]}
                            lineHeight={["20.74px","0px"]}
                            textAlign="center">

                              {game.name}
                            </Text>
                          </Center>
                        </Center>
                      </CardBody>
                    </Card>
                  </Box>
                ))
              );
            })}
        </Stack>


       </VStack>
    </Box>
  );
}
